.logo {
    width: 100%;
    height: 50px;
    justify-content: start;
    align-items: start;
    display: flex;
    flex-direction: row;
    margin-right: 850px;
}

.logo img {
    width: 40%;
    height: auto;
}

@media (max-width: 600px) {
    .logo img {
        width: 80%;
    }
}

@media (max-width: 400px) {
    .logo img {
        width: 80%;
    }
}