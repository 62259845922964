.menu-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.menu-container button {
    width: 400px;
    height: 50px;
    background-color: #fff;
    border-radius: 20px;
    border: none;
    font-size: 20px;
    cursor: pointer;
    border-radius: 10px;
}

.menu-container button:hover {
    color: #000000
}

@media (max-width: 768px) {
    .menu-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .menu-container button {
        width: 80vw;
        margin-left: 40px;
        height: auto;
        margin-bottom: 10px;
    }
}