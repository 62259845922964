.headers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 20vh;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 15px;
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* Use 100vh for full viewport height */
    background-color: #57565685;
    animation: backgroundAnimation 5s infinite alternate-reverse;
}

@media (max-width: 600px) {
    .headers {
        font-size: 30px;
        /* Adjust font size for smaller screens */
    }
}

@media (max-width: 400px) {
    .headers {
        font-size: 20px;
        /* Further adjust font size for even smaller screens */
    }
}


@keyframes backgroundAnimation {
    from {
        background-color: #57565685
    }

    to {
        background-color: #36353585
    }

}
