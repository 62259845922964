.form-container{
  width: 100%;
  height: 77vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1{
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  border-radius: 15px;
  color: #fff;
  margin-top: 20px;
  width: 300px;
}



form{
  width: 700px;
  height: 400px;
  padding: 0 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  background: #fff;
  border-radius: 20px;
}


.input-container {
    position: relative;
    margin: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 30px;
  }
  
  .input[type="text"] {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
    color: #333;
    width: 250px;
    outline: none;
  }
  
  .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    transition: width 0.3s;
  }
  
  .input[type="text"]:focus + .underline {
    width: 100%;
  }

  .input[type="text"]:placeholder-shown + label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #777;
    pointer-events: none;
    transition: transform 0.3s, font-size 0.3s, color 0.3s;
  }
  
  .input[type="text"]:focus:not(:placeholder-shown) + label {
    transform: translateY(-25px);
    font-size: 12px;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    animation: floating-label 0.3s forwards;
  }

  .input:hover{
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    color: #ffffff;
  }

  .input[type="text"]:focus + .underline {
    width: 100%;
  }
  
  @keyframes floating-label {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
  
    100% {
      transform: translateY(-25px);
      opacity: 0;
    }
  }
  
  .input[type="text"]:placeholder-shown + label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #777;
    pointer-events: none;
    transition: transform 0.3s, font-size 0.3s, color 0.3s;
  }
  
  .input[type="text"]:focus:not(:placeholder-shown) + label {
    transform: translateY(-25px);
    font-size: 12px;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    animation: floating-label 0.3s forwards;
  }
  
  

 select{
    padding: 10px;
    font-size: 15px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
    color: #333;
    width: 350px;
    outline: none;
    margin: 10px;
  }

  select:hover{
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    color: #fff;
  }

option{
  color: #ffffff;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  
}

 

  button{
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
    color: #333;
    width: 200px;
    outline: none;
    margin: 10px;
    cursor: pointer;
  }
  button:hover{
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    color: #fff;
  }


  @media screen and (max-width: 768px) {
    .form-container{
      width: 100vw;
      height: 80vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1{
      font-size: 30px;
    }

    p{
      font-size: 15px;
    }

    form{
      width: 400px;
      height: 400px;
    }

    button{
      width: 300px;
    }

    select{
      width: 300px;
    }

    input{
      width: 300px;
    }

  }